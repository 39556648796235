.hirer-users-tab {
  .m-0 {
    margin: 0;
  }

  .my-details-card {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    margin-top: 2rem;
  }
}