.page-hirer-payment-feedback {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .text-center {
    text-align: center;
  }

  .text-primary {
    color: #574DD3;
  }

  .text-danger {
    color: red;
  }
}