.page-talent-ratings {
  width: 100%;
  padding-bottom: 86px;

  .p-0 {
    padding: 0;
  }

  .m-0 {
    margin: 0;
  }

  .underline {
    text-decoration: underline;
  }

  .title-container {
    justify-content: space-between;
    margin-top: 24px;
  }
}