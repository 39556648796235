.page-talent-form {
  width: 100%;
  padding: 0 16px;

  .site-navigation-steps {
    padding: 0 40px;
  }

  .spacer {
    margin-top: calc(86px + 24px); /*height of bottom nav bar + padding*/
  }
}
