.hirer-user-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  .bold {
    font-weight: 600;
  }

  .m-0 {
    margin: 0;
  }

  .user-name {
    font-size: x-large;
  }

  .delete-button {
    color: red;
    padding: 0;
  }
}