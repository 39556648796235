.allowance-form-item {
  .allowance-type {
    margin-bottom: 0;
    flex: 1
  }

  .btn-remove-allowance {
    margin-top: auto;
    margin-left: auto;
    color: red;
  }
}