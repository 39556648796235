.page-hirer-claim-profile {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .bold {
    font-weight: 600;
  }

  .text-center {
    text-align: center;
  }
}