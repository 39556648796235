.page-hirer-schedule-detail {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .icon {
    font-size: large;
    padding: 0 0.25rem;
  }

  .space-time-picker {
    display: flex;

    .form-item-time-picker {
      margin-bottom: 0.5rem;
    }

    .ant-space-item:last-child {
      flex-grow: 1;
      display: flex;

      .ms-auto {
        margin-left: auto;
      }
    }
  }

  .space-date-picker {
    display: flex;
    justify-content: space-between;

    .form-item-date-picker {
      margin-bottom: 1rem;
      margin-right: auto;
    }
  }

  .end-of-list {
    display: flex;
    justify-content: center;
    color: #555;

    span {
      padding: 0.5rem;
    }
  }

  .end-of-list::before,
  .end-of-list::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #aaa;
    margin: auto;
  }

  .end-of-list::before {
    margin-left: 10px;
  }

  .end-of-list::after {
    margin-right: 10px;
  }

  .span-optional {
    font-size: x-small;
    font-style: italic;
  }
}