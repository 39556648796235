.page-rate-talent-complete {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .text-center {
    text-align: center;
  }

  .top-content {
    padding: 0 1rem;

    .stars-wrapper {
      margin-top: 3.5rem;
      justify-content: center;
      margin-bottom: 2rem;
  
      .anticon-star {
        color: #574DD3;
        font-size: 3rem;
        margin: 0 5px;
      }
    }
  
    .title {
      text-align: center;
    }
  }

  .separate-line {
    border-color: #EEEEEE;
    border-width: 0.75px;
    border-top: 0;
    margin: 2.5rem 0.5rem;
  }

  .continue-text {
    font-weight: 600;
    font-size: large;
    margin-bottom: 1rem;
  }
}