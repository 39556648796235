.component-invite-casting-form {
    .mb-0 {
        margin-bottom: 0;
    }

    .schedule-error {
        font-size: small;
        color: red;
        margin-bottom: 1rem;
    }

    .btn-submit {
        margin: 1rem 0;
    }
}