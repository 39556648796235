.page-enquiry-submitted {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    padding: 80px 48px 40px 48px;
    flex-direction: row;
    align-items: center;

    .thankyou-image {
      background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/comprehensive-support.png');
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      margin: auto;
      width: 30vw;
      height: 30vw;
      max-width: 50vh;
      max-height: 50vh;
      border-radius: 9999px;
    }
  }

  .footer {
    margin-top: auto;
  }
}

@media screen and (max-width: 600px) {

  .page-enquiry-submitted {
    .content {
      padding: 80px 16px 40px 16px;
      flex-direction: column;

      .thankyou-image {
        width: 80vw;
        height: 80vw;
        margin-bottom: 32px;
      }
    }
}
  }