.div-invoice-details {
  .rounded {
    border-radius: 5px;
  }

  .bold {
    font-weight: 600;
  }

  .small {
    font-size: small;
  }

  .text-muted {
    opacity: 0.5;
  }

  .justify-between {
    justify-content: space-between;
  }

  .title-container {
    align-items: center;
    margin-bottom: 1.5rem;

    .title.ant-typography {
      margin-top: 12px;
      margin-bottom: 0;
      font-weight: 600;
    }

    .btn-report-issue {
      color: red;
    }
  }

  .invoice-item {
    justify-content: space-between;
    line-height: 1.25;

    .btn-view-receipt {
      .component-button {
        border: 0;
        padding: 0;
        font-size: small;
      }
    }

    .btn-allowance-item {
      .component-button {
        padding: 0;
        padding-right: 1rem;
        text-align: left;
      }
    }
  }

  .btn-add-allowance {
    .component-button {
      padding: 0;
    }
  }

  .collapse-talent-details.ant-collapse-item {

    .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .btn-change-payment-method {
      .component-button {
        border: 0;
        padding: 0;
        font-size: small;
      }
    }
  }
}