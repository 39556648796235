.page-hirer-form {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .add-calendar {
    margin-left: 0.85rem;
    color: black;
    border: none;
  }

  .btn-delete {
    margin-top: 16px;
  }

}

