.hirer-to-rate-card {
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.hirer-to-rate-card:not(.no-style) {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  }

.hirer-to-rate-card:not(.no-style):not(:first-child) {
    margin-top: 16px;
  }

.hirer-to-rate-card:not(.no-style):hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

.hirer-to-rate-card {

  .ant-typography {
    display: block;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .header {
    padding: 16px;
    align-items: center;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .left-column {
      flex: 1;

      .photo {
        flex: 1 1 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .right-column {
      flex: 2;
      align-items: center;

      .summary {
        padding: 8px 20px;

        .applicant-name {
          font-size: large;
          font-weight: 600;
        }

        .role-title {
          font-size: medium;
          color: black;
        }

        .shoot-dates {
          font-size: small;
          color: #444444;
        }
      }
    }
  }
}

.hirer-to-rate-card.no-style {
    .body {
      border-bottom: 0;
    }
  }

.hirer-to-rate-card {

  .action {
    .action-button {
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      background-color: #fff;
      color: #574dd3;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }

      .ant-typography {
        color: #574dd3;
        font-weight: 600;
        font-size: 1.05rem;
        margin: 0;
      }
    }
  }
}

.modal-view-talent-profile {
  .ant-modal-content {
    background-color: transparent;

    .ant-modal-body {
      padding: 0;
    }
  }
}