.page-reset-password {
  font-family: 'Nunito';
  background: linear-gradient(to right, #1e5799 0%,#2ce0bf 19%,#76dd2c 40%,#dba62b 60%,#e02cbf 83%,#1e5799 100%);
  background-size: 10000px 100%;
  animation: bg 15s linear infinite;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .mobile-view {
    display: flex;
    max-width: 450px;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -10px 28px 6px rgba(0,0,0,0.3);
    background-color: #fff;
    min-height: 100vh;
  }
}