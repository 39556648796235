.role-action-dropdown {
  .action-button {
    padding-top: 16px;
    padding-bottom: 16px;
    border: none;
    background-color: #fff;
    color: #574dd3;
    cursor: pointer;

    .anticon {
      font-size: 1.5rem;
      margin-right: 16px;
    }

    .ant-typography {
      color: #574dd3;
      font-weight: 600;
      font-size: 1.05rem;
    }
  }
}
