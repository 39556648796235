.rate-written-review {
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
    font-size: large;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    text-align: center;
  }

  .check-icon {
    color: #574DD3;
  }

  .txt-written-review {
    width: 100%;
    margin-bottom: 2rem;

    textarea {
      border-color: #A4A4A4;
    }

    .error-message {
      font-size: small;
      color: red;
    }
  }

  .note {
    text-align: center;
  }
}