.component-footer {
  width: 100% !important
}

.component-footer {
  padding: 50px;
  background-color: #483f3f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .social-media-icon {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
