$project-title: 1.063rem; //17px
$role-title: 0.875rem; // 14px
$copy: 0.875rem; //14px
$link-button: 0.688rem; // 11px
$description: 0.8rem; // 11px
$tag: 0.563rem; //9px

.application-card {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.23);

  .bold {
    font-weight: 600;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .link-button {
    font-size: $link-button;
  }

  .icon {
    margin-right: 0.5rem;
  }


  .project-title {
    font-size: $project-title;
    display: block;
    padding: 1rem;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .project-roles {
    .role-title {
      background: #d9d9d9;
      font-size: $role-title;
      justify-content: space-between;
      background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
      color: white;

      span.ant-typography {
        display: flex;
        align-items: center;
      }
    }

    .view-details-link-button {
      position: absolute;
      font-size: $link-button;
      right: 16px;
    }

    .status-tag {
      background: rgba(255,255,255,0.15686);
      padding: 2px 0.75rem;
      border-radius: 3px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }

    .recommend-tag {
      font-size: $tag;
    }

    .role-requirements {
      text-transform: capitalize;
    }

    .role-description {
      font-size: $description;
      word-wrap: break-word;
    }

    .btn-submit-allowance .component-button {
      padding-top: 0;
      padding-bottom: 0;
      font-size: small;
      margin-left: 0.5rem;
    }

      .btn-submit-allowance .component-button:disabled {
        color: #A4A4A4;
        background-color: transparent;
      }

    .btn-show-more{
      .component-button {
        padding: 0;
        padding-bottom: 0.5rem;
        font-size: small;
      }
    }

    .action {
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      button, a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;
        padding: 16px 0;
        text-align: center;
        border: none;
        background-color: #fff;
        color: #574dd3;
        cursor: pointer;

        .anticon {
          font-size: 1.5rem;
        }

        .ant-typography {
          color: #574dd3;
          font-weight: 600;
          font-size: 1.05rem;
          margin-left: 16px;
        }
      }
    }
  }

  .text-success {
    color: #52c41a;
  }
}