.component-activity-flyout {
  overflow: auto;

  .component-flyout-item {
    font-weight: normal;
  }

  .activity-flyout-item {
    .activity-icon {
      margin-right: 0.5rem;
      margin-top: 0.25rem;
    }
  
    .bold {
      font-weight: 600;
    }
  }
  

  .end-of-list {
    display: flex;
    justify-content: center;
    color: #555;
    margin-bottom: 24px;

    span {
      padding: 0.5rem;
    }
  }

  .end-of-list::before,
  .end-of-list::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #aaa;
    margin: auto;
  }

  .end-of-list::before {
    margin-left: 10px;
  }

  .end-of-list::after {
    margin-right: 10px;
  }
}
