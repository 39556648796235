.page-view-ratings {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
}