.agenda-card {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.23);

  .capitalize {
    text-transform: capitalize;
  }

  .bold {
    font-weight: 600;
  }

  .icon {
    margin-right: 0.5rem;
  }

  .text-red {
    color: red;
  }

  .talent-photo {
    flex: 1 1 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .schedule-info {
    flex: 2 2 0;

    .agenda-card-header {
      align-items: center;
      padding: 1rem;
      background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        display: block;
      }

      .header-title {
        font-size: 1.063rem;
        line-height: 1em;
        margin-bottom: 0.5em;
      }

      .header-subtitle {
        font-size: small;
        line-height: 1em;
      }
    }

    .project-details {
      font-size: 0.875rem;
      padding: 5px 0 5px 1rem;

      .scheduled-time {
        display: inline-block;
      }

      .scheduled-time::first-letter {
        text-transform: uppercase;
      }

      .activity-type {
        text-transform: capitalize;
      }

      .withdraw-reason {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: normal;
      }

      .view-profile-button {
        font-size: 0.8rem;
        padding: 0;
        font-weight: normal;
        margin-left: auto;
      }
    }
  }
}