.page-job-history {
  width: 100%;
  
  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }

  .select-filter {
    .selector {
      display: block;
    }
  }

  .project-list {
    /*bottom nav height*/
    margin-bottom: 100px;
    max-width: 100vw;

    .end-of-list {
      display: flex;
      justify-content: center;
      color: #555;

      span {
        padding: 0.5rem;
      }
    }

    .end-of-list::before,
    .end-of-list::after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid #aaa;
      margin: auto;
    }

    .end-of-list::before {
      margin-left: 10px;
    }

    .end-of-list::after {
      margin-right: 10px;
    }
  }
}
