$border-radius: 4px;
$card-section-divider: 1px solid rgba(0, 0, 0, 0.1);

.applicant-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border-radius: $border-radius;
}

.applicant-card:not(:first-child) {
    margin-top: 32px;
  }

.applicant-card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

.applicant-card {

  .ant-typography {
    display: block;
  }

  .items-center {
    align-items: center;
  }

  .bold {
    font-weight: 600;
  }

  .text-black {
    color: black;
  }

  .text-right {
    text-align: right;
  }

  .header {
    padding: 16px;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: #fff;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .heading {
      flex: 1 1 0;

      .title {
        font-size: 1.1rem;
      }

      .subtitle {
        font-size: 0.75rem;
      }
    }

    .applicant-status {
      flex: 0 1 0;
      align-items: center;
      justify-content: center;
    }

      .applicant-status>* {
        min-width: 88px;
        padding: 8px;
        text-align: center;
        background: rgba(255,255,255,0.15686);
        border-radius: 4px;

        .anticon {
          margin-right: 0.5rem;
        }

        .capitalize {
          text-transform: capitalize;
        }
      }

    .applicant-status {

      .align-center {
        align-items: center;
      }

      .casting-button {
        border: none;
        cursor: pointer;
      }
    }
  }

  .profile {
    .left-column {
      flex-direction: column;
      flex-basis: 30px;

      .photo {
        flex: 1 1 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      .body-figure {
        text-align: center;

        .unit {
          padding-left: 1px;
          font-size: 0.65rem;
          letter-spacing: 1px;
        }
      }
    }

    .info {
      flex: 2 1 0;

      .summary {
        padding: 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .social-media-container {
          align-items: end;
        }

        .recommend-tag {
          display: inline-block;
          border-radius: 3px;
          padding: 2px 12px;
          background-color: #7cb305;
          color: #fff;
          margin-bottom: 4px;
          font-size: 0.6rem;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }

      .gallery {
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 8px;

        .image-container {
          position: relative;
          padding: 0.5rem;
          flex: 1 1 2rem;

          img {
            display: block;
            border-radius: 4px;
            width: 100%;
            height: 3rem;
            object-fit: cover;
            object-position: 50% 20%;
          }

          .show-more {
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            font-size: 1rem;
            background-color: rgba(0, 0, 0, 0.7);
            width: calc(100% - 1rem);
            height: calc(100% - 1rem);
            border-radius: 4px;
            margin: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .experiences {
        padding: 8px 16px;

        .experience:not(:first-child) {
          margin-top: 4px;
        }
      }

      .self-casting-video {
        padding: 8px 16px;
        font-weight: 600;

        img {
          max-width: 100%;
          height: 3rem;
          object-fit: cover;
        }
      }
    }
  }

  .rating {
    border-top: $card-section-divider;
    padding: 10px 20px;

    .rating-item {
      justify-content: space-between;
      align-items: baseline;

      .info-icon {
        margin-left: 5px;
        color: #A4A4A4;
      }
    }

    .btn-view-details {
      font-weight: 600;
      color: #574DD3;
    }
  }

  .action {
    border-top: $card-section-divider;

    a,
    button {
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      background-color: #fff;
      color: #574dd3;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }

      .ant-typography {
        color: #574dd3;
        font-weight: 600;
        font-size: 1.05rem;
      }
    }

      a:first-child, button:first-child {
        border-bottom-left-radius: $border-radius;
      }

      a:last-child, button:last-child {
        border-bottom-right-radius: $border-radius;
      }

    .btn-view-profile {
      padding: 0;
      color: #574dd3;
    }

    .btn-view-profile:hover {
      color: #574dd3;
    }

    button:disabled span,
    button[disabled] span {
      color: #999999;
    }
  }
}