.page-job-directory {
  width: 100%;
  padding-bottom: 86px;

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }
  .end-of-list {
    display: flex;
    justify-content: center;
    color: #555;
    margin-bottom: 24px;

    span {
      padding: 0.5rem;
    }
  }

  .end-of-list::before,
  .end-of-list::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #aaa;
    margin: auto;
  }

  .end-of-list::before {
    margin-left: 10px;
  }

  .end-of-list::after {
    margin-right: 10px;
  }
}
