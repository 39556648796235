.page-prompt-notification{
  padding: 0 16px;
  margin-bottom: 100px;
  text-align: center;

  .image-box {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }

  div.ant-typography {
    font-size: 1rem; 
  }

  .component-button  {
    background-color:  #574DD3;
    color: white;
  }

  .link-button {
    padding: 1rem 1.5rem;
    margin: 16px 0 ;
    background-color: transparent;
    color: #574DD3;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 21.2px;
  }
}
