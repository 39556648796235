.pending-activity-card {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.23);

  .bold {
    font-weight: 600;
  }

  .text-right {
    text-align: right;
  }

  .role-title {
    background: #d9d9d9;
    font-size: 0.875rem;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: white;
  }

  .action {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      background-color: #fff;
      color: #574dd3;
      cursor: pointer;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }

      .ant-typography {
        color: #574dd3;
        font-weight: 600;
        font-size: 1.05rem;
      }
    }
  }
}