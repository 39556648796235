.div-meal-form {
  .bold {
    font-weight: 600;
  }

  .small {
    font-size: small;
  }

  .w-full {
    width: 100%;
  }

  .text-red {
    color: red;
  }

  .lbl-upload-receipt {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .div-receipt-upload {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .preview-wrapper {
      height: 3rem;
      align-items: center;
      cursor: pointer;

      .img-preview {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin-right: 1rem;
      }

      .img-preview-name {
        display: block;
        height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}