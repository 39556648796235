.page-prompt-install {
  padding: 0 16px;
  margin-bottom: 100px;
  text-align: center;

  .logo-box {
    display: flex;
    justify-content: center;
    margin: 16px 0; 
  }

  .benefit-list {
    padding: 0 16px;
    font-size: 1rem; 
    text-align: left;
  }

  .check-icon {
    color: #574DD3;
    font-size: 1rem 
  }

  .component-button  {
    background-color:  #574DD3;
    color: white;
  }
}
