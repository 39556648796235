.page-home {
  //types
  h1.ant-typography,
  h4.ant-typography {
    font-family: 'Inter', sans-serif;
  }

  h3.ant-typography {
    font-family: 'Karla', sans-serif;
    font-weight: 400;
  }

  span.ant-typography {
    font-size: 16px;
  }

  button {
    margin-left: 0 !important;
    font-size: 18px !important;
  }

  button {
    background-color: #95141c;
    flex-shrink: 0;
    font-family: 'Lato', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    max-height: 55px;
    max-width: 15rem;
    line-height: normal;
    border-radius: 0;
    border: none;

    span {
      font-size: 1.125rem !important;
    }

    span {
      padding: 0;
      margin: 0;
      font-style: normal;
      line-height: normal;
      color: white;
    }
  }

    button.ant-btn {
      min-width: 12rem;
    }

    button.ant-btn:hover {
      background-color: #b44a51;
    }

    button.navigation-button {
      background: transparent;
      cursor: pointer;
    }

    button.navigation-button:hover {
      background-color: transparent;
    }

  .bg-video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: 0;
  }

    #bg-video-horizontal.bg-video {
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }

    //less than 800px
    #bg-video-vertical.bg-video {
      width: 100vw;
      display: none;
      object-fit: cover;
    }

  .float-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 2rem;
    right: 2rem;
    z-index: 1;
    background-color: #2AB13F;
    color: white;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
    font-size: xx-large;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    .title-container {
      height: 100%;
      min-height: 100vh;
      padding: 0 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background-color: transparent;
      text-shadow: -1px -1px 0 #aaa, 1px -1px 0 #aaa, -1px 1px 0 #aaa,
        1px 1px 0 #aaa;

      h1 {
        padding: 0;
        margin: 0;
      }

      span {
        margin: 1rem 0rem;
      }

      h1,
      span {
        color: #fff;
        font-family: 'Lato', sans-serif;
      }
    }

    span.ant-typography {
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #fff;
    }

    h1.ant-typography {
      font-family: 'Inter', sans-serif;
      opacity: 0;
      font-weight: 400;
      line-height: normal;
      font-size: 4rem;
      animation: title-fade-in 0.7s ease-out forwards;
    }

    h1.ant-typography:nth-child(2) {
      animation-delay: 0.3s;
    }

    h1.ant-typography:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}
@media screen and (max-width: 600px) {
  .page-home {

  .section-title {
      .title-container {
        padding: 0 16px;
      }

      h1.ant-typography {
        font-size: 1.5rem;
      }
  }
}
    }
.page-home {

  .section-title {

    @keyframes title-fade-in {
      from {
        transform: translateY(-25px);
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    button {
      font-family: 'Karla';
    }
  }

  .section {
    min-height: 50vh;
    padding: 144px 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .ant-btn:not(:first-child) {
      margin-left: 16px;
    }
  }
}
@media screen and (max-width: 600px) {
  .page-home {
    .section {
      padding: 144px 16px;

      .ant-btn {
        width: 100%;
      }

      .ant-btn:not(:first-child) {
        margin-left: 0px;
        margin-top: 16px;
      }
    }
}
  }
.page-home {

  .swiper-mobile {
    display: none;
  }

  .centered-slide {
    display: flex;
    justify-content: center;
  }

  //mobile styles
}
@media screen and (max-width: 1100px) {
  .page-home {
    .testimonial-swiper {
      display: flex !important;
    }
    .testimonial-swiper {
      flex-direction: row;
      align-items: center;
    }
    .contact-us-inner {
      padding: 0 !important
    }
    .contact-us-inner {
      .left-container {
        h1 {
          font-size: 2.5rem !important;
        }
        .contact-us-image {
          img {
            width: 80%;
            min-width: 200px;
          }
        }
      }
    }

    .lights-camera-action {
      padding: 144px 16px !important
    }

    .lights-camera-action {
      h5 {
        font-size: 1.1rem !important;
      }
    }
}
  }
@media screen and (max-width: 600px) {
  .page-home {
      #bg-video-horizontal.bg-video {
        display: none;
      }
      #bg-video-vertical.bg-video {
        display: block;
      }

    .swiper-slide-active.logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .section .about-us {
      flex-direction: column;
      align-items: flex-start;

      h1 {
        padding-bottom: 2rem;
      }

      h4 {
        display: inline;
      }

      span {
        display: block;
        padding-top: 1rem;
      }
    }

    .section.feat {
      flex-direction: column;
      gap: 2rem;
    }

    .section.testimonial {
      h1 {
        width: 100%;
        text-align: center;
      }
    }

    .section.what-makes-us-special {
      display: flex;
      align-items: center;

      .mobile {
        display: block !important;
      }

      .what-makes-us-special-image {
        display: none;
      }
    }

    .content {
      width: 100% !important;
    }

    .section.what-we-do {
      background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/instant-talent-bg.png');

      button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }

    .section.effortless-casting {
      background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/effortless-casting-experience-bg.png');

      .text-container {
        width: auto !important;
      }

      button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }

    .section.comprehensive-support {
      background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/comprehensive-support-bg.png');

      button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }

    .section.influencer-marketing-simplified {
      background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/influencer-marketing-simplified-bg.png');

      .text-container {
        width: auto !important;
      }

      button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }

    .mobile.mobile-only {
      display: block !important;
    }

    .mobile-image {
      display: block !important;
    }

    .mobile-image {
      width: 100%;
    }

    .swiper {
      display: none;
    }

    .swiper-mobile {
      display: block;
    }

    .lights-camera-action {
      padding: 144px 16px !important
    }

    .lights-camera-action {
      h5 {
        font-size: 1.1rem !important;
      }
    }

    .contact-us-title {
      width: 50% !important;
    }

    .contact-us-inner {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      align-items: stretch !important;
      padding: 0 !important
    }

    .contact-us-inner {

      h1 {
        font-size: 2.5rem !important;
      }

      h1.bigger {
        font-size: 3rem !important;
      }
    }

    img.mobile {
      max-width: 100%;
    }

    .contact-us-image {
      width: 13rem !important
    }

    .contact-us-image {
      img {
        width: 60%;
      }
    }

    .contact-us-title {
      font-size: 1.1rem !important;
      height: 3rem !important;
    }

    .what-we-do-title-container {
      height: 3rem !important;
    }

    .what-we-do-title-container {
      h1 {
        font-size: 1.1rem !important;
      }
    }
}
  }
.page-home {

  .ant-btn {
    height: auto;
    padding: 12px 24px;

    span {
      font-family: 'Karla', sans-serif;
      font-size: 20px;
    }
  }

  .about-us {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      [dir="ltr"] h1 {
        text-align: left;
      }

      [dir="rtl"] h1 {
        text-align: right;
      }

      h1 {
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        margin: 0;
        font-size: 4rem;
      }
    }

    div {
      flex: 1;
    }

    h4,
    h1,
    span {
      color: #707070 !important;
    }
  }

  .feat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    gap: 1rem;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .ant-card {
      text-align: center;
      height: 16rem;
      width: 24rem;
      border-radius: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3.69rem;
      h1,
      h2 {
        color: #707070 !important;
      }
      h1,
      h2 {
        font-family: 'Lato', sans-serif;
      }
      h1 {
        font-size: 3.75rem;
        font-weight: 900;
        margin: 0;
      }

      h2 {
        font-size: 2.25rem;
        margin: 0;
        font-weight: 400;
      }
    }
  }

  .what-makes-us-special {
    span {
      font-family: 'Lato', sans-serif;
    }
    h1,
    h3,
    span {
      color: #707070 !important;
    }
    h1 {
      color: #707070 !important;
    }
    h1 {
      font-family: 'Inter', sans-serif;
      font-weight: normal;
      width: 100%;
      text-align: center;
      font-size: 4rem;
    }
    h3 {
      font-size: 2.25rem;
    }
    span {
      font-size: 1.5rem;
    }
    .what-makes-us-special-image {
      width: 100%;
      display: block;
    }
  }

  .mobile.mobile-image {
    display: none;
  }

  .mobile.mobile-only {
    display: none;
  }

  .what-we-do {
    position: relative;
    background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/instant-talent.png');
    background-size: cover;
    background-position: top;

    .what-we-do-title-container {
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      width: 30%;
      justify-content: center;
      align-items: center;
      display: flex;
      h1 {
        color: #707070 !important;
      }
    }

    .content {
      width: 50%;
    }
  }

  .mobile.instant-talent-image {
    display: none;
  }

  .contact-us {
    color: #707070 !important
  }

  .contact-us {
    position: relative;
    display: block;

    h1.ant-typography {
      color: #707070 !important;
    }

    h1.ant-typography {

      margin: 0;
      font-size: 3.75rem;
      font-weight: 400;
    }
    h1.bigger {
      font-size: 4rem;
      margin-bottom: 3rem;
    }

    .contact-us-title {
      background-color: #483f3f;
      width: 39rem;
      height: 6.5rem;
      color: white;
      font-family: 'Inter', sans-serif;
      font-size: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      max-width: 27rem !important;
    }
    .contact-us-inner {
      padding: 6rem;
      display: grid;
      align-items: flex-end;
      width: 100%;
      grid-template:
        'left right' 1fr
        'left right' 1fr
        'left button' 1fr / 1fr 1fr;
    }

    .submit {
      grid-area: button;
    }

    .left-container {
      grid-area: left;
      height: 100%;
    }

    .right-container {
      grid-area: right;
      margin-top: 1rem;
    }

    input {
      border-bottom: solid 1px #707070;
      border-radius: 0;
    }
    h4 {
      color: #707070 !important;
    }
    h4 {
      font-size: 1.5rem;

      font-family: 'Lato', sans-serif;
      font-weight: 400;
    }
  }

  .effortless-casting {
    position: relative;
    background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/effortless-casting-experience.png');
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-end;
    .text-container {
      width: 500px;
    }
  }

  .banner-title {
    margin: 0 !important;
  }

  .banner-title {
    color: #fff;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }

  .description {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: block;
  }

  .comprehensive-support {
    position: relative;
    background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/comprehensive-support.png');
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-start;

    .content {
      width: 50%;
    }
  }

  .influencer-marketing-simplified {
    position: relative;
    background-image: url('https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/influencer-marketing-simplified.png');
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: flex-end;
    .text-container {
      width: 500px;
    }
  }

  .testimonial {
    .testimonial-swiper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      width: 100%;
      justify-content: center;

      .swiper {
        display: block;
      }
    }

    h1 {
      width: 100%;
      text-align: center;
    }

    .ant-card {
      flex: 1;
      text-align: center;
      align-items: center;
      max-width: 24rem;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border-radius: 0.4rem;
    }
  }

  .featured {
    max-width: 100vw;
    align-items: center;

    .logos {
      max-width: 100vw;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .logo-container {
        padding: 0 16px;
        margin: 0 auto 5px auto;
        width: 160px;

        img {
          width: 128px;
        }
      }
    }
  }

  .endorse {
    align-items: center;

    .logos {
      align-items: center;
      justify-content: center;

      .logo-container {
        width: 160px;
        padding: 0 16px;

        img {
          width: 100%;
        }
      }
    }
  }

  .lights-camera-action {
    padding-left: 20rem;
    h1,
    h5 {
      color: white;
    }

    h1 {
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 4.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h5 {
      color: #fff;
      font-family: 'Inter', sans-serif;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    background-color: #e2bbbb;
  }

  .our-client {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    .our-client-inner {
      width: 80vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .swiper .swiper-wrapper {
        align-items: center;

        .logo-container {
          text-align: center;
        }
      }
    }

    h1 {
      color: #707070;
      font-family: 'Inter', sans-serif;
      font-size: 3rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 3rem;
    }
  }
}
