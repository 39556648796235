.page-faq {
  .statement {
    padding: 80px 48px 40px 48px;
  }
}
@media screen and (max-width: 600px) {
  .page-faq {
    .statement {
      padding: 80px 16px 40px 16px;
    }
}
  }
