.avatar-upload {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  .cursor-pointer {
    cursor: pointer;
  }

  .upload-avatar-button {
    cursor: pointer;
    color: black;
    border: 1px solid #A4A4A4;
    border-radius: 50%;
    height: 6rem;
    width: 6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 2.5rem;
    }
  }

  .avatar-preview {
    height: 6rem;
    width: 6rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #A4A4A4;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .remove-avatar-button {
    padding: 0;
    color: red;
  }
}