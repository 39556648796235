.page-rate-talent {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .cursor-pointer {
    cursor: pointer;
  }

  .talent-container {
    .left-column {
      flex: 1;

      .photo {
        flex: 1 1 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .right-column {
      flex: 2;
      align-items: center;

      .summary {
        padding: 8px 20px;

        .applicant-name {
          font-size: large;
          font-weight: 600;
        }

        .role-title {
          font-size: medium;
          color: black;
        }

        .shoot-dates {
          font-size: small;
          color: #444444;
        }
      }
    }
  }
}