.page-hirer-schedules {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .page-title {
    justify-content: space-between;
    align-items: center;

    h4.ant-typography {
      flex: 1 1 0;
    }

    .button-container {
      justify-content: end;
    }
  }
}
