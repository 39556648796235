.page-talent-view-comments {
  width: 100%;
  padding-bottom: 86px;

  .title-container {
    margin-top: 24px;

    .title.ant-typography {
      margin-bottom: 0;
      margin-right: auto;
    }

    .btn-report-issue {
      color: red;
    }
  }
}