.page-talent-agenda {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .page-talent-agenda {
    .title-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
}
  }