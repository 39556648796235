.page-talent-profile {
  font-family: 'Nunito';
  background: linear-gradient(to right, #1e5799 0%,#2ce0bf 19%,#76dd2c 40%,#dba62b 60%,#e02cbf 83%,#1e5799 100%);
  background-size: 10000px 100%;
  animation: bg 15s linear infinite;
  display: flex;
  justify-content: center;

  .mobile-view {
    max-width: 450px;
    box-shadow: 0px -10px 28px 6px rgba(0,0,0,0.3);
  }

  .profile-photo {
    height: 80vh;
    position: relative;
    
    .photo-info {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 64px 16px 16px 16px;
      width: 100%;
      background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 70%, rgba(255,255,255,0) 100%);
    }
  }

  .info {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 16px;
    background-color: #fff;

    section {
      h4.ant-typography {
        margin-bottom: 0.5rem;
        font-family: 'Montserrat Alternates';
        font-size: 1.7rem;
        margin: 1.5rem 0;
      }

      div.ant-typography {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 0.75rem;
      }

      .attribute-detail {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.3rem;
      }

      .attribute-detail.small {
        font-size: 1.1rem;
      }
    }

    .image-container {
      position: relative;
      padding: 0.5rem;
      flex: 1 1 7rem;
      width: 8rem;

      img {
        display: block;
        border-radius: 4px;
        width: 100%;
        height: 6rem;
        object-fit: cover;
        object-position: 50% 20%;
      }

      .show-more {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 2.5rem;
        background-color: rgba(0,0,0,0.7);
        width: calc(100% -  1rem);
        height: calc(100% -  1rem);
        border-radius: 4px;
        margin: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .arrow-wrapper {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      top: -1.5rem;
      left: 0;
      font-size: 1.3rem;
      color: #fff;
      animation: hint-arrow 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite;
    }

    @keyframes hint-arrow {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-6px);
      }

      100% {
        transform: translateY(0);
      }
    }
  }

    .info::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 4px;
      top: 4px;
      left: calc(50% - 8px);
      background-color: #9a9a9a;
    }

  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
    width: auto;
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    width: calc(100% - 100px);
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: 60px;
  }

  .social-link-container {
    margin-right: 2rem;
    min-width: 60px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .talent-profile-rating-section {
    .mb-0 {
      margin-bottom: 0;
    }
  
    .p-0 {
      padding: 0;
    }
  
    .items-center {
      align-items: center;
    }
  
    .bold {
      font-weight: 600;
    }
  
    .text-black {
      color: black;
    }

    .text-right {
      text-align: right;
    }
  
    .section-title {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;
    }
  
    .rating-item-container {
      .rating-item {
        justify-content: space-between;
        align-items: baseline;
  
        .info-icon {
          margin-left: 5px;
          color: #A4A4A4;
        }
      }
    }
  
    .written-review-container {
      margin-top: 2.5rem;
      flex-wrap: nowrap;
      overflow-x: auto;
      max-width: calc(100vw - 32px);
    }
  
      .written-review-container::-webkit-scrollbar {
        height: 10px;
      }
      .written-review-container::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      .written-review-container::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
      }
      .written-review-container::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  
    .written-review-container {
  
      .written-review-item {
        background-color: #EEEEEE;
        border-radius: 6px;
        padding: 1rem 1.5rem;
        flex: 0 0 90%;
        margin-bottom: 8px;
        cursor: pointer;
      }

        .written-review-item:only-child {
          margin: auto;
        }
  
        .written-review-item:not(:last-child) {
          margin-right: 0.75rem;
        }
  
      .written-review-item {
  
        .author-container {
          margin-bottom: 1rem;
  
          .author-profile-pic {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: cover;
            border-radius: 50px;
            margin-right: 1rem;
          }
  
          .author-name {
            font-weight: 600;
            font-size: medium;
          }
  
          .comment-date {
            font-size: small;
            color: #A4A4A4;
          }
  
          .action-dropdown {
            margin-left: auto;
            text-align: right;
            padding-right: 1rem;
  
            .dropdown-btn {
              font-size: large;
              color: #A4A4A4;
            }
          }
        }
      }
  
      .btn-load-more {
        margin: auto;
  
        .anticon {
          font-size: x-large;
        }
      }
    }
  }
}

a.share-icon {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.85);
}
