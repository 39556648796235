.rate-performance-metrics {
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
    font-size: large;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .error-message {
    font-size: small;
    color: red;
    text-align: center;
  }

  .separate-line {
    border-color: #EEEEEE;
    border-width: 0.75px;
    border-top: 0;
    margin: 1.5rem 0.5rem;
  }

  .metrics-title {
    justify-content: center;
    align-items: center;
    font-size: medium;
    font-weight: 600;

    .title-icon {
      margin-left: 0.35rem;
      color: #A4A4A4;
    }
  }

  .metrics-item {
    display: flex;
    padding: 0 2rem;
    color: #574DD3;

    .star-wrapper {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .ant-rate-star {
        font-size: 3rem;
        margin: 0;
      }

      .star-desc {
        font-size: small;
        color: #A4A4A4
      }
    }
  }
}
