.page-hirer-base {
  font-family: 'Nunito';
  background: linear-gradient(to right, #1e5799 0%,#2ce0bf 19%,#76dd2c 40%,#dba62b 60%,#e02cbf 83%,#1e5799 100%);
  background-size: 10000px 100%;
  animation: bg 15s linear infinite;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .content {
    max-width: 450px;
    min-height: 100vh;
    box-shadow: 0px -10px 28px 6px rgba(0,0,0,0.3);
    background-color: #fff;
    padding-bottom: 100px;

    h4.ant-typography {
      margin-bottom: 0.5rem;
      font-size: 1.7rem;
      font-weight: 400;
      margin: 1.5rem 0;
    }
  }
}