
  @media only screen and (max-width: 480px) {
  .talent-agenda-tab {
    .date-divider {
      padding-left: 1rem;
      padding-right: 1rem;
    }
}
  }
.talent-agenda-tab {

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }
}