.page-hirer-login {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .text-center {
    text-align: center;
  }

  .text-red {
    color: red;
  }
}