.page-hirer-agenda {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .title-container {
    h4.ant-typography {
      flex: 1 1 0;
    }
  }
}
