#enquiry-form {
  margin-left: 2rem;
}
@media screen and (max-width: 600px) {
  #enquiry-form {
    margin-left: 0;
}
  }
#enquiry-form {
  label {
    color: #707070 !important;
  }
  label {
    font-size: 1rem;
  }

  input {
    border-color: #707070;
    stroke: #707070;
  }

  .ant-btn:focus {
    background-color: #933339;
  }

  .submit.sent {
    background-color: rgb(50, 223, 108);
    transition: background-color 0.2s;
  }

  .submit {
    width: 100%;
    max-width: 100%;
  }

  .ant-form-item {
    .ant-row.ant-form-item-row {
      display: grid;
      .ant-col.ant-form-item-label {
        text-align: left;
      }
    }
  }
}
