.page-talent-submit-allowance {
  width: 100%;
  padding: 0 16px 86px 16px;

  .bold {
    font-weight: 600;
  }

  .block {
    display: block;
  }

  .title-container {
    justify-content: space-between;
    align-items: center;

    .title {
      text-transform: capitalize;
    }

    .btn-close {
      font-size: large;
      color: black;
    }
  }

  .select-allowance-type.ant-select-disabled {
    .ant-select-selection-item {
      color: black;
    }
  }
}