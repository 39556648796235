.page-hirer-view-comments {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .title-container {
    .title.ant-typography {
      margin-bottom: 0;
    }

    .btn-report-issue {
      color: red;
    }
  }
}